import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import projects from '../../data/ProjectsData.json'

export default function Project() {
    // let projectId = sessionStorage.getItem("projectId");

    const [projectId, setProjectId]=useState(sessionStorage.getItem("projectId"))
    const [project, setProject] = useState({})
    const navigate = useNavigate()

    useEffect(() => {
        if (!projectId) {
            navigate("/projects")
        }

        const select = projects.find(pro => pro.id == projectId)
        console.log(select);
        setProject(select)
        window.scrollTo(0, 0);
    }, [projectId])

    const nextProjectsHandler = () => {
       const catg = projects.filter(pro => pro.type == project.type)
       const currentInd = catg.findIndex(p=>p.id == projectId)
       if(catg.length != currentInd + 1 ){
        const nextProject = catg[currentInd + 1]
        setProjectId(nextProject.id)
        sessionStorage.setItem("projectId", nextProject.id)
       }
    }

    const previousProjectHandler=()=>{
        const catg = projects.filter(pro => pro.type == project.type)
       const currentInd = catg.findIndex(p=>p.id == projectId)
       if(currentInd > 0 ){
        const previous = catg[currentInd - 1]
        setProjectId(previous.id)
        sessionStorage.setItem("projectId", previous.id)
       }
    }

    return (
        <div class="content-wrapper">
            <section class="content-lines-wrapper">
                <div class="content-lines-inner">
                    <div class="content-lines"></div>
                </div>
            </section>
            <section class="banner-header banner-img valign bg-img bg-fixed" data-overlay-darkgray="5" data-background="assets/img/banner.jpg">
                <div class="left-panel"></div>
            </section>
            <section class="section-padding2">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <h2 class="section-title2">{project.title}</h2> </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8">
                            <p>{project.description}</p>
                            {/* <p>Architecture non lorem ac erat suscipit bibendum. Nulla facilisi. Sedeuter nunc volutpat, mollis sapien vel, conseyer turpeutionyer masin libero sempe. Fusceler mollis augue sit amet hendrerit vestibulum. Duisteyerionyer venenatis lacus.</p>
                            <p>Villa gravida eros ut turpis interdum ornare. Interdum et malesu they adamale fames ac anteipsu pimsine faucibus. Curabitur arcu site feugiat in tortor in, volutpat sollicitudin libero.</p> */}
                        </div>
                        <div class="col-md-4">
                            <p><b>Year : </b> {project.year}</p>
                            <p><b>Company : </b> {project.company}</p>
                            <p><b>Project Name : </b> {project.title}</p>
                            <p><b>Location : </b> {project.location}</p>
                        </div>
                    </div>
                    <div class="row mt-30">
                        {project.images && project.images.map(img => {
                            return (<div class="col-md-6 gallery-item">
                                <a href="#" title="Architecture" class="img-zoom">
                                    <div class="gallery-box">
                                        <div class="gallery-img"> <img src={img} class="img-fluid mx-auto d-block" alt="work-img" /> </div>
                                    </div>
                                </a>
                            </div>)
                        })}

                    </div>
                </div>
            </section>
            <section class="projects-prev-next">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="d-sm-flex align-items-center justify-content-between">
                                <div class="projects-prev-next-left">
                                    <a href="#" onClick={previousProjectHandler}> <i class="ti-arrow-left"></i> Previous Project</a>
                                </div> <Link to="/projects"><i class="ti-layout-grid3-alt"></i></Link>
                                <div class="projects-prev-next-right"> <a href="#" onClick={nextProjectsHandler}>Next Project <i class="ti-arrow-right"></i></a> </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
