import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import projectData from '../data/ProjectsData.json'

export default function HomePage() {
    useEffect(() => {
        // Load custom.js file
        const script = document.createElement('script');
        script.src = 'assets/js/custom.js'; // Replace with the correct path if needed
        script.async = true;
        document.body.appendChild(script);
        window.scrollTo(0, 0);

        // Make sure to return a cleanup function to remove the script when the component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, []);
    return (
        <>
            <div className="App">
                {/* <div className="preloader-bg"></div><div id="preloader"><div id="preloader-status"><div className="preloader-position loader"><span></span></div></div></div> */}

                <header className="header slider-fade">
                    <div className="owl-carousel owl-theme">
                        <div className="text-end item bg-img" data-overlay-dark="3" data-background="assets/img/slider/Matrix1.jpg">
                            <div className="v-middle caption mt-30">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-7 offset-md-5">
                                            {/* <h1>Innovate Desing in Toronto</h1>
                                            <p>Architecture viverra tellus nec massa dictum the euismoe.
                                                <br />Curabitur viverra the posuere aukue velit.
                                            </p> */}
                                            {/* <div className="butn-light mt-30 mb-30"><a href="https://1.envato.market/mDnXD" target="_blank"><span>Buy Now</span></a></div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="text-end item bg-img" data-overlay-dark="4" data-background="assets/img/slider/2.jpg">
                            <div className="v-middle caption mt-30">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-7 offset-md-5">
                                            <h1>Innovate Desing in Canada</h1>
                                            <p>Architecture viverra tellus nec massa dictum the euismoe.
                                                <br />Curabitur viverra the posuere aukue velit.
                                            </p>
                                            {/* <div className="butn-light mt-30 mb-30"><a href="https://1.envato.market/mDnXD" target="_blank"><span>Buy Now</span></a></div> */}
                        {/* </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className="hero-corner"></div>
                    <div className="hero-corner3"></div>
                    <div className="left-panel">
                        <ul className="social-left clearfix">
                            <li><a href="#"><i className="ti-pinterest"></i></a></li>
                            <li><a href="#"><i className="ti-instagram"></i></a></li>
                            <li><a href="#"><i className="ti-twitter"></i></a></li>
                            <li><a href="#"><i className="ti-facebook"></i></a></li>
                        </ul>
                    </div>
                </header>
                <div className="content-wrapper">
                    <section className="content-lines-wrapper">
                        <div className="content-lines-inner">
                            <div className="content-lines"></div>
                        </div>
                    </section>
                    <section className="about section-padding">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 mb-30 animate-box" data-animate-effect="fadeInUp">
                                    <h2 className="section-title">About <span>Intexture Design Studio</span></h2>
                                    <p>Architecture and the pursuit of the built environment, offers  various challenges in the modern context. In a fast changing  to a responsible world from a thrifty society of the recent past,  the need of the hour is to design with nature.</p>
                                    <p>Buildings are not to be built on a site, but rather it should grow  from the site, as if it belongs there…. in harmony with the  resources of the site – like the slopes, the rock formations, the  trees and vegetation, the play of hard and soft paved areas.</p>
                                    <p>The rain water collection & harvesting and minimising surface  run off, building with sun and wind, building to keep heat out  in summer and get heated up in winter....And all these form  the back bone of contemporary sustainable building  philosophy.</p>                                </div>
                                <div className="col-md-6 animate-box" data-animate-effect="fadeInUp">
                                    <div className="about-img">
                                        <div className="img"> <img src="assets/img/slider/2.jpg" className="img-fluid" alt="" /> </div>
                                        <div className="about-img-2 about-buro">Singapore Office</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="projects section-padding">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <h2 className="section-title">Our <span>Projects</span></h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="owl-carousel owl-theme">
                                        {projectData.map((proj, i) => {
                                            if (i < 4) {
                                                return (
                                                    <div className="item">
                                                        <div className="position-re o-hidden"> <img src={proj.images[0]} alt="" /> </div>
                                                        <div className="con">
                                                            <h6><Link to="/project" onClick={() => sessionStorage.setItem("projectId", proj.id)}>{proj.type}</Link></h6>
                                                            <h5><Link to="/project" onClick={() => sessionStorage.setItem("projectId", proj.id)}>{proj.title}</Link></h5>
                                                            <div className="line"></div> <Link to="/project" onClick={() => sessionStorage.setItem("projectId", proj.id)}><i className="ti-arrow-right"></i></Link>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })}
                                        {/* <div className="item">
                                            <div className="position-re o-hidden"> <img src="assets/img/projects/1.jpg" alt="" /> </div>
                                            <div className="con">
                                                <h6><Link to="#">Interior</Link></h6>
                                                <h5><Link to="#">Cotton House</Link></h5>
                                                <div className="line"></div> <Link to="#"><i className="ti-arrow-right"></i></Link>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="position-re o-hidden"> <img src="assets/img/projects/2.jpg" alt="" /> </div>
                                            <div className="con">
                                                <h6><Link to="#">Exterior</Link></h6>
                                                <h5><Link to="#">Armada Center</Link></h5>
                                                <div className="line"></div> <Link to="#"><i className="ti-arrow-right"></i></Link>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="position-re o-hidden"> <img src="assets/img/projects/3.jpg" alt="" /> </div>
                                            <div className="con">
                                                <h6><Link to="#">Urban</Link></h6>
                                                <h5><Link to="#">Stonya Villa</Link></h5>
                                                <div className="line"></div> <Link to="#"><i className="ti-arrow-right"></i></Link>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="position-re o-hidden"> <img src="assets/img/projects/4.jpg" alt="" /> </div>
                                            <div className="con">
                                                <h6><Link to="#">Interior</Link></h6>
                                                <h5><Link to="#">Prime Hotel</Link></h5>
                                                <div className="line"></div> <Link to="#"><i className="ti-arrow-right"></i></Link>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="services section-padding">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <h2 className="section-title">Our <span>Services</span></h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="item">
                                        <Link to="#"> <img src="assets/img/icons/icon-1.png" alt="" />
                                            <h5>Architecture</h5>
                                            <div className="line"></div>
                                            {/* <p>Architecture bibendum eros eget lacus the vulputate sit amet vehicuta nibhen ulicera in the vitae miss.</p> */}
                                            <div className="numb">01</div>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="item">
                                        <Link to="#"> <img src="assets/img/icons/icon-2.png" alt="" />
                                            <h5>Interior Design</h5>
                                            <div className="line"></div>
                                            {/* <p>Architecture bibendum eros eget lacus the vulputate sit amet vehicuta nibhen ulicera in the vitae miss.</p> */}
                                            <div className="numb">02</div>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="item">
                                        <Link to="#"> <img src="assets/img/icons/icon-3.png" alt="" />
                                            <h5>Urban Design</h5>
                                            <div className="line"></div>
                                            {/* <p>Architecture bibendum eros eget lacus the vulputate sit amet vehicuta nibhen ulicera in the vitae miss.</p> */}
                                            <div className="numb">03</div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* <section className="bauen-blog section-padding">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <h2 className="section-title">Current <span>News</span></h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="owl-carousel owl-theme">
                                        <div className="item">
                                            <div className="position-re o-hidden"> <img src="assets/img/slider/1.jpg" alt="" /> </div>
                                            <div className="con">
                                                <span className="category">
                                                    <Link to="#">Architecture </Link> - 20.12.2023
                                                </span>
                                                <h5><Link to="#">Modern Architectural Structures</Link></h5>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="position-re o-hidden"> <img src="assets/img/slider/2.jpg" alt="" /> </div>
                                            <div className="con"> <span className="category">
                                                <Link to="#">Interior</Link> - 18.12.2023
                                            </span>
                                                <h5><Link to="#">Modernism in Architecture</Link></h5>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="position-re o-hidden"> <img src="assets/img/slider/3.jpg" alt="" /> </div>
                                            <div className="con"> <span className="category">
                                                <Link to="#">Urban</Link> - 16.12.2023
                                            </span>
                                                <h5><Link to="#">Postmodern Architecture</Link></h5>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="position-re o-hidden"> <img src="assets/img/slider/4.jpg" alt="" /> </div>
                                            <div className="con"> <span className="category">
                                                <Link to="#">Planing</Link> - 14.12.2023
                                            </span>
                                                <h5><Link to="#">Modern Architecture Buildings</Link></h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> */}
                </div>
            </div>
        </>
    )
}
