import React, { useEffect } from 'react'

export default function Team() {
    useEffect(() => {
        // Load custom.js file
        const script = document.createElement('script');
        script.src = 'assets/js/custom.js'; // Replace with the correct path if needed
        script.async = true;
        document.body.appendChild(script);
        window.scrollTo(0, 0);

        // Make sure to return a cleanup function to remove the script when the component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, []);
    return (
        <div class="content-wrapper">
            <section class="content-lines-wrapper">
                <div class="content-lines-inner">
                    <div class="content-lines"></div>
                </div>
            </section>
            <section class="banner-header banner-img valign bg-img bg-fixed" data-overlay-darkgray="5" data-background="assets/img/banner.jpg"> </section>
            <section class="team section-padding2">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <h2 class="section-title">Our <span>Team</span></h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="item">
                                <a href="#"><img src="assets/img/team/1.jpg" alt="" /></a>
                                <div class="info">
                                    <h6>Jason Brown</h6>
                                    <p>Architect</p>
                                    <div class="social valign">
                                        <div class="full-width">
                                            <p>dipl. Arch ETH/SIA</p> <a href="#"><i class="ti-facebook"></i></a> <a href="#"><i class="ti-twitter-alt"></i></a> <a href="#"><i class="ti-instagram"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="item">
                                <div class="img">
                                    <a href="#"><img src="assets/img/team/2.jpg" alt="" /></a>
                                </div>
                                <div class="info">
                                    <h6>Emily White</h6>
                                    <p>Architect</p>
                                    <div class="social valign">
                                        <div class="full-width">
                                            <p>dipl. Arch FH</p> <a href="#"><i class="ti-facebook"></i></a> <a href="#"><i class="ti-twitter-alt"></i></a> <a href="#"><i class="ti-instagram"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="item">
                                <div class="img">
                                    <a href="#"><img src="assets/img/team/3.jpg" alt="" /></a>
                                </div>
                                <div class="info">
                                    <h6>Enrico Samara</h6>
                                    <p>Architect</p>
                                    <div class="social valign">
                                        <div class="full-width">
                                            <p>M.A. FH in Architecture</p> <a href="#"><i class="ti-facebook"></i></a> <a href="#"><i class="ti-twitter-alt"></i></a> <a href="#"><i class="ti-instagram"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="item">
                                <div class="img">
                                    <a href="#"><img src="assets/img/team/4.jpg" alt="" /></a>
                                </div>
                                <div class="info">
                                    <h6>Jesica Smith</h6>
                                    <p>Architect</p>
                                    <div class="social valign">
                                        <div class="full-width">
                                            <p>M.A. FH in Architecture</p> <a href="#"><i class="ti-facebook"></i></a> <a href="#"><i class="ti-twitter-alt"></i></a> <a href="#"><i class="ti-instagram"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="item">
                                <div class="img">
                                    <a href="#"><img src="assets/img/team/5.jpg" alt="" /></a>
                                </div>
                                <div class="info">
                                    <h6>Olivia Brown</h6>
                                    <p>Architect</p>
                                    <div class="social valign">
                                        <div class="full-width">
                                            <p>dipl. Arch FH</p> <a href="#"><i class="ti-facebook"></i></a> <a href="#"><i class="ti-twitter-alt"></i></a> <a href="#"><i class="ti-instagram"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="item">
                                <div class="img">
                                    <a href="#"><img src="assets/img/team/6.jpg" alt="" /></a>
                                </div>
                                <div class="info">
                                    <h6>Fredia Martin</h6>
                                    <p>Architect</p>
                                    <div class="social valign">
                                        <div class="full-width">
                                            <p>M.A. FH in Architecture</p> <a href="#"><i class="ti-facebook"></i></a> <a href="#"><i class="ti-twitter-alt"></i></a> <a href="#"><i class="ti-instagram"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
