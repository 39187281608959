import React from "react";
import { Link, useLocation } from "react-router-dom";

export default function Header() {
  const location = useLocation();

  return (
    <nav class="navbar navbar-expand-lg">
      <div class="logo-wrapper valign">
        <div class="logo">
          <Link to="/">
            {" "}
            <img src="IDS 1 PNG.png" class="logo-img" alt="" />
           
          </Link>
        </div>
      </div>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbar"
        aria-controls="navbar"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        {" "}
        <span class="navbar-toggler-icon">
          <i class="ti-menu"></i>
        </span>{" "}
      </button>
      <div class="collapse navbar-collapse" id="navbar">
        <ul class="navbar-nav ms-auto">
          <li class="nav-item">
            <Link
              class={`nav-link ${location.pathname === "/" ? "active" : ""}`}
              to="/"
            >
              Home
            </Link>
          </li>
          <li class="nav-item">
            <Link
              class={`nav-link ${
                location.pathname === "/about" ? "active" : ""
              }`}
              to="/about"
            >
              About
            </Link>
          </li>
          <li class="nav-item">
            <Link
              class={`nav-link ${
                location.pathname === "/services" ? "active" : ""
              }`}
              to="/services"
            >
              Services
            </Link>
          </li>
          <li class="nav-item">
            <Link
              class={`nav-link ${
                location.pathname === "/projects" ? "active" : ""
              }`}
              to="/projects"
            >
              Projects
            </Link>
          </li>
          <li class="nav-item">
            <Link
              class={`nav-link ${
                location.pathname === "/contact" ? "active" : ""
              }`}
              to="/contact"
            >
              Contact Us
            </Link>
          </li>
          {/* <li class="nav-item"><Link class={`nav-link ${location.pathname === "/team" ? "active" : ""}`} to="/team">Team</Link></li> */}
          {/* <li class="nav-item dropdown"> <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">Services <i class="ti-angle-down"></i></a>
                        <ul class="dropdown-menu">
                            <li><a href="services.html" class="dropdown-item"><span>Services 1</span></a></li>
                            <li><a href="services2.html" class="dropdown-item"><span>Services 2</span></a></li>
                            <li><a href="services3.html" class="dropdown-item"><span>Services 3</span></a></li>
                            <li><a href="services4.html" class="dropdown-item"><span>Services 4</span></a></li>
                        </ul>
                    </li>
                    <li class="nav-item dropdown"> <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">Projects <i class="ti-angle-down"></i></a>
                        <ul class="dropdown-menu">
                            <li><a href="projects.html" class="dropdown-item"><span>Projects 1</span></a></li>
                            <li><a href="projects2.html" class="dropdown-item"><span>Projects 2</span></a></li>
                            <li><a href="projects3.html" class="dropdown-item"><span>Projects 3</span></a></li>
                            <li><a href="projects4.html" class="dropdown-item"><span>Projects 4</span></a></li>
                            <li><a href="project-gallery.html" class="dropdown-item"><span>Project Gallery</span></a></li>
                            <li><a href="project-page.html" class="dropdown-item"><span>Project Page</span></a></li>
                        </ul>
                    </li>
                    <li class="nav-item dropdown"> <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">Pages <i class="ti-angle-down"></i></a>
                        <ul class="dropdown-menu">
                            <li><a href="video.html" class="dropdown-item"><span>Video Background</span></a></li>
                            <li><a href="before-after.html" class="dropdown-item"><span>Before After</span></a></li>
                            <li><a href="pricing.html" class="dropdown-item"><span>Pricing</span></a></li>
                            <li><a href="team.html" class="dropdown-item"><span>Team</span></a></li>
                            <li><a href="careers.html" class="dropdown-item"><span>Careers</span></a></li>
                            <li><a href="gallery.html" class="dropdown-item"><span>Gallery</span></a></li>
                            <li><a href="video-gallery.html" class="dropdown-item"><span>Video Gallery</span></a></li>
                            <li><a href="process.html" class="dropdown-item"><span>Process</span></a></li>
                            <li class="dropdown-submenu dropdown"> <a class="dropdown-item dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false" href="#"><span>Other Pages <i class="ti-angle-right"></i></span></a>
                                <ul class="dropdown-menu">
                                    <li><a href="testimonials.html" class="dropdown-item"><span>Testimonials</span></a></li>
                                    <li><a href="faqs.html" class="dropdown-item"><span>Faqs</span></a></li>
                                    <li><a href="not-found-404.html" class="dropdown-item"><span>404 Page</span></a></li>
                                    <li><a href="coming-soon.html" class="dropdown-item"><span>Coming Soon</span></a></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item dropdown"> <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">Blog <i class="ti-angle-down"></i></a>
                        <ul class="dropdown-menu">
                            <li><a href="blog.html" class="dropdown-item"><span>Blog 1</span></a></li>
                            <li><a href="blog2.html" class="dropdown-item"><span>Blog 2</span></a></li>
                            <li><a href="blog3.html" class="dropdown-item"><span>Blog 3</span></a></li>
                            <li><a href="blog4.html" class="dropdown-item"><span>Blog 4</span></a></li>
                        </ul>
                    </li>
                    <li class="nav-item"><a class="nav-link" href="contact.html">Contact</a></li> */}
        </ul>
      </div>
    </nav>
  );
}
