import React, { useEffect } from 'react'

export default function About() {
    useEffect(() => {
        // Load custom.js file
        const script = document.createElement('script');
        script.src = 'assets/js/custom.js'; // Replace with the correct path if needed
        script.async = true;
        document.body.appendChild(script);
        window.scrollTo(0, 0);

        // Make sure to return a cleanup function to remove the script when the component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, []);
    return (
        <div class="content-wrapper">
            <section class="content-lines-wrapper">
                <div class="content-lines-inner">
                    <div class="content-lines"></div>
                </div>
            </section>
            <section class="banner-header banner-img valign bg-img bg-fixed" data-overlay-darkgray="5" data-background="assets/img/banner.jpg">
                <div class="left-panel"></div>
            </section>
            <section class="about section-padding2">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 mb-30 animate-box" data-animate-effect="fadeInUp">
                            <h2 class="section-title">About <span>Intexture Design Studio</span></h2>
                            <p>Architecture and the pursuit of the built environment, offers  various challenges in the modern context. In a fast changing  to a responsible world from a thrifty society of the recent past,  the need of the hour is to design with nature.</p>
                            <p>Buildings are not to be built on a site, but rather it should grow  from the site, as if it belongs there…. in harmony with the  resources of the site – like the slopes, the rock formations, the  trees and vegetation, the play of hard and soft paved areas.</p>
                            <p>The rain water collection & harvesting and minimising surface  run off, building with sun and wind, building to keep heat out  in summer and get heated up in winter....And all these form  the back bone of contemporary sustainable building  philosophy.</p>
                        </div>
                        <div class="col-md-6 animate-box" data-animate-effect="fadeInUp">
                            <div class="about-img">
                                <div class="img"> <img src="assets/img/slider/2.jpg" class="img-fluid" alt="" /> </div>
                                <div class="about-img-2 about-buro">Singapore Office</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section class="team section-padding">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <h2 class="section-title">Our <span>Team</span></h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 owl-carousel owl-theme">
                            <div class="item">
                                <div class="img"> <a href="team-details.html"><img src="assets/img/team/1.jpg" alt="" /></a> </div>
                                <div class="info">
                                    <h6>Jason Brown</h6>
                                    <p>Architect</p>
                                    <div class="social valign">
                                        <div class="full-width">
                                            <p>dipl. Arch ETH/SIA</p> <a href="#"><i class="ti-facebook"></i></a> <a href="#"><i class="ti-twitter-alt"></i></a> <a href="#"><i class="ti-instagram"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="item">
                                <div class="img"> <a href="team-details.html"><img src="assets/img/team/2.jpg" alt="" /></a> </div>
                                <div class="info">
                                    <h6>Emily White</h6>
                                    <p>Architect</p>
                                    <div class="social valign">
                                        <div class="full-width">
                                            <p>dipl. Arch FH</p> <a href="#"><i class="ti-facebook"></i></a> <a href="#"><i class="ti-twitter-alt"></i></a> <a href="#"><i class="ti-instagram"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="item">
                                <div class="img"> <a href="team-details.html"><img src="assets/img/team/3.jpg" alt="" /></a> </div>
                                <div class="info">
                                    <h6>Enrico Samara</h6>
                                    <p>Architect</p>
                                    <div class="social valign">
                                        <div class="full-width">
                                            <p>M.A. FH in Architecture</p> <a href="#"><i class="ti-facebook"></i></a> <a href="#"><i class="ti-twitter-alt"></i></a> <a href="#"><i class="ti-instagram"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="item">
                                <div class="img"> <a href="team-details.html"><img src="assets/img/team/4.jpg" alt="" /></a> </div>
                                <div class="info">
                                    <h6>Jesica Smith</h6>
                                    <p>Architect</p>
                                    <div class="social valign">
                                        <div class="full-width">
                                            <p>M.A. FH in Architecture</p> <a href="#"><i class="ti-facebook"></i></a> <a href="#"><i class="ti-twitter-alt"></i></a> <a href="#"><i class="ti-instagram"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="item">
                                <div class="img"> <a href="team-details.html"><img src="assets/img/team/5.jpg" alt="" /></a> </div>
                                <div class="info">
                                    <h6>Olivia Brown</h6>
                                    <p>Architect</p>
                                    <div class="social valign">
                                        <div class="full-width">
                                            <p>dipl. Arch FH</p> <a href="#"><i class="ti-facebook"></i></a> <a href="#"><i class="ti-twitter-alt"></i></a> <a href="#"><i class="ti-instagram"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="item">
                                <div class="img"> <a href="team-details.html"><img src="assets/img/team/6.jpg" alt="" /></a> </div>
                                <div class="info">
                                    <h6>Fredia Martin</h6>
                                    <p>Architect</p>
                                    <div class="social valign">
                                        <div class="full-width">
                                            <p>M.A. FH in Architecture</p> <a href="#"><i class="ti-facebook"></i></a> <a href="#"><i class="ti-twitter-alt"></i></a> <a href="#"><i class="ti-instagram"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="positions section-padding">
                <div class="container">
                    <div class="row">
                        <div class="col-md-8 offset-md-2 mb-30 text-center">
                            <h2 class="section-title">Job <span>Opening</span></h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 mb-60">
                            <div class="position"><a class="position-link" href="contact.html"></a>
                                <div class="position-title">Project Manager <span>Development</span></div>
                                <div class="position-location"><span>Location</span> New York, NY</div>
                                <div class="position-time">Employment Type <span>Full-time</span></div>
                                <div class="position-icon"><i class="ti-arrow-top-right"></i></div>
                            </div>
                            <div class="position"><a class="position-link" href="contact.html"></a>
                                <div class="position-title">Staff Design Architect <span>Development</span></div>
                                <div class="position-location"><span>Location</span> San Francisco, CA</div>
                                <div class="position-time">Employment Type <span>Full-time</span></div>
                                <div class="position-icon"><i class="ti-arrow-top-right"></i></div>
                            </div>
                            <div class="position"><a class="position-link" href="contact.html"></a>
                                <div class="position-title">Senior Exhibit Designer <span>Development</span></div>
                                <div class="position-location"><span>Location</span> New York, NY</div>
                                <div class="position-time">Employment Type <span>Full-time</span></div>
                                <div class="position-icon"><i class="ti-arrow-top-right"></i></div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <p><small>Please submit a resume and cover letter with compensation history to <b>architecture[at]Matrix.com</b>.</small></p>
                        </div>
                    </div>
                </div>
            </section> */}
        </div>
    )
}
