import React from "react";
import SubFooter from "./SubFooter";

export default function Footer() {
  const handleLinkClick = (url) => {
    window.open(url, "_blank");
  };

  return (
    <>
      <SubFooter />
      <footer class="main-footer dark">
        <div class="container">
          <div class="row">
            <div class="col-md-4 mb-30">
              <div class="item fotcont">
                <div class="fothead">
                  <h6>Phone</h6>
                </div>
                <p>+91 7039 465631</p>
              </div>
            </div>
            <div class="col-md-4 mb-30">
              <div class="item fotcont">
                <div class="fothead">
                  <h6>Email</h6>
                </div>
                <p>info@intexturedesignstudio.com</p>
              </div>
            </div>
            <div class="col-md-4 mb-30">
              <div class="item fotcont">
                <div class="fothead">
                  <h6>Our Branches</h6>
                </div>
                <p>
                  Office No. 625, Clover Hill Plaza, NIBM Road, Kondhwa, Pune, Pune, Maharashtra, 411048
                </p>
                
              </div>
             
            </div>
          </div>
        </div>
        <div class="sub-footer">
          <div class="container">
            <div class="row">
              <div class="col-md-4">
                <div class="text-left">
                  <p>
                    © 2023 Intexture Design Studio Architects. All right
                    reserved.
                  </p>
                </div>
              </div>
              <div class="col-md-4 abot">
                <div class="social-icon">
                  <a
                    href="#"
                    onClick={() =>
                      handleLinkClick(
                        "https://www.facebook.com/profile.php?id=61555262590277"
                      )
                    }
                  >
                    <i class="ti-facebook"></i>
                  </a>
                  <a
                    href="#"
                    onClick={() =>
                      handleLinkClick(
                        "https://www.instagram.com/intexturedesignstudio/?igsh=cnp4ejM4azc5bGoy"
                      )
                    }
                  >
                    <i class="ti-instagram"></i>
                  </a>
                  <a
                    href="#"
                    onClick={() =>
                      handleLinkClick(
                        "https://in.linkedin.com/company/intexture-design-studio-llp"
                      )
                    }
                  >
                    <i class="ti-linkedin"></i>
                  </a>
                </div>
              </div>
              <div class="col-md-4">
                <p class="right">
                  <a href="#">Powered By Salaf Technocrats</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
