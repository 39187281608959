import logo from './logo.svg';
import './App.css';
import HomePage from './pages/HomePage';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from './components/header/Header';
import ProgressBar from './components/ProgressBar';
import About from './pages/About';
import Footer from './components/footer/Footer';
import OurServices from './pages/services/OurServices';
import OurService from './pages/services/OurService';
import OurProjects from './pages/projects/OurProjects';
import Project from './pages/projects/Project';
import { useEffect } from 'react';
import Contact from './pages/Contact';
import Team from './pages/Team';
import TeamDetails from './pages/team/TeamDetails';

function App() {
  // useEffect(() => {
  //   // Load custom.js file
  //   const script = document.createElement('script');
  //   script.src = 'assets/js/custom.js'; // Replace with the correct path if needed
  //   script.async = true;
  //   document.body.appendChild(script);

  //   // Make sure to return a cleanup function to remove the script when the component unmounts
  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path={`${process.env.PUBLIC_URL}/`} element={<HomePage />} />
        <Route path={`${process.env.PUBLIC_URL}/about`} element={<About />} />
        <Route path={`${process.env.PUBLIC_URL}/contact`} element={<Contact />} />
        <Route path={`${process.env.PUBLIC_URL}/services`} element={<OurService />} />
        <Route path={`${process.env.PUBLIC_URL}/projects`} element={<OurProjects />} />
        <Route path={`${process.env.PUBLIC_URL}/project`} element={<Project />} />
        <Route path={`${process.env.PUBLIC_URL}/team`} element={<Team />} />
        {/* <Route path={`${process.env.PUBLIC_URL}/team-details`} element={<TeamDetails />} /> */}
      </Routes>
      <ProgressBar />
      <Footer />
    </BrowserRouter>
  );
}

export default App;
