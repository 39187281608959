import React, { useEffect } from 'react'

export default function OurService() {
    useEffect(() => {
        // Load custom.js file
        const script = document.createElement('script');
        script.src = 'assets/js/custom.js'; // Replace with the correct path if needed
        script.async = true;
        document.body.appendChild(script);
        window.scrollTo(0, 0);

        // Make sure to return a cleanup function to remove the script when the component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, []);
    return (
        <div class="content-wrapper">

            <section class="content-lines-wrapper">
                <div class="content-lines-inner">
                    <div class="content-lines"></div>
                </div>
            </section>

            <section class="banner-header banner-img valign bg-img bg-fixed" data-overlay-darkgray="5" data-background="assets/img/banner.jpg">
                <div class="left-panel"></div>
            </section>
            <section class="services section-padding2">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 animate-box" data-animate-effect="fadeInUp">
                            <h2 class="section-title">Our <span>Services</span></h2> </div>
                        <p>Intexture Design Studio Architect incorporated prides itself in being one of its kind of development  firm which provides end to end solution in terms of design, and project  management with office in Pune and design partners in Singapore & Dubai we are poised to offer high end  consultancy services and turn key execution for the selected and discerning  clientele.
                        </p>
                    </div>
                    <div class="row">

                        <div class="col-md-4">
                            <div class="services3">
                                <div class="services3-img-area"><img src="assets/img/icons/icon-1.png" alt="" /></div>
                                <a href="architecture.html">
                                    <div class="services3-text-area">
                                        <h4 class="services3-heading">Architecture</h4>
                                        <p>Architecture bibendum evinta anne the vutate sit amen vehicuta nibhen.</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="services3">
                                <div class="services3-img-area"><img src="assets/img/icons/icon-2.png" alt="" /></div>
                                <a href="interior-design.html">
                                    <div class="services3-text-area">
                                        <h4 class="services3-heading">Interior Design</h4>
                                        <p>Architecture bibendum evinta anne the vutate sit amen vehicuta nibhen.</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="services3">
                                <div class="services3-img-area"><img src="assets/img/icons/icon-3.png" alt="" /></div>
                                <a href="urban-design.html">
                                    <div class="services3-text-area">
                                        <h4 class="services3-heading">Design and Building</h4>
                                        <p>Architecture bibendum evinta anne the vutate sit amen vehicuta nibhen.</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="services3">
                                <div class="services3-img-area"><img src="assets/img/icons/icon-4.png" alt="" /></div>
                                <a href="planning.html">
                                    <div class="services3-text-area">
                                        <h4 class="services3-heading">Infrastructure Planing</h4>
                                        <p>Architecture bibendum evinta anne the vutate sit amen vehicuta nibhen.</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="services3">
                                <div class="services3-img-area"><img src="assets/img/icons/icon-5.png" alt="" /></div>
                                <a href="3d-modelling.html">
                                    <div class="services3-text-area">
                                        <h4 class="services3-heading">Strategic Workplace</h4>
                                        <p>Architecture bibendum evinta anne the vutate sit amen vehicuta nibhen.</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
