import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import projectData from '../../data/ProjectsData.json'

export default function OurProjects() {

    const [projects, setProjects] = useState(projectData)

    useEffect(() => {
        // Load custom.js file
        const script = document.createElement('script');
        script.src = 'assets/js/custom.js'; // Replace with the correct path if needed
        script.async = true;
        document.body.appendChild(script);
        window.scrollTo(0, 0);
        // Make sure to return a cleanup function to remove the script when the component unmounts
        return () => {
            document.body.removeChild(script);
        };

    }, [projects]);

    // useEffect(()=>{
    //     setProjects(projectData.splice(0, 0))
    // },[])

    // const loadMore = () => {
    //     const loaded = projectData.splice(projects.length - 1, 10)
    //     setProjects(projects.concat(loaded))
    // }
    // console.log(projects);
    return (
        <div class="content-wrapper">
            <section class="content-lines-wrapper">
                <div class="content-lines-inner">
                    <div class="content-lines"></div>
                </div>
            </section>
            <section class="banner-header banner-img bg-img bg-fixed pb-0" data-background="assets/img/banner.jpg" data-overlay-darkgray="5">
                <div class="left-panel"></div>
            </section>
            <section class="bauen-project section-padding2">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 text-center animate-box" data-animate-effect="fadeInUp">
                            <h2 class="section-title">Our <span>Projects</span></h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 text-center">
                            <ul class="bauen-project-filter">
                                <li class="active" data-filter="*">All</li>
                                <li data-filter=".hospitality">Hospitality</li>
                                <li data-filter=".residential">Residential</li>
                                <li data-filter=".commercial">Commercial</li>
                                <li data-filter=".retails">Retails</li>
                                <li data-filter=".clubs">Clubs</li>
                                <li data-filter=".stadium">Stadium</li>
                            </ul>
                        </div>
                    </div>
                    <div class="row bauen-project-items">
                        {projects.map(project => {
                            return (<div class={`col-md-6 single-item ${project.type}`}>
                                <div class="bauen-project-wrap">
                                    <Link to="/project" onClick={() => sessionStorage.setItem("projectId", project.id)}><img src={project.images[0]} alt="" /></Link>
                                    <div class="bauen-project-content">
                                        <p>{project.type}</p>
                                        <h3><Link to="/project">{project.title}</Link></h3>
                                    </div>
                                </div>
                            </div>)
                        })}
                        {/* <div class="col-md-6 single-item hospitality">
                            <div class="bauen-project-wrap">
                                <Link to="/project"><img src="assets/img/projects/01.jpg" alt="" /></Link>
                                <div class="bauen-project-content">
                                    <p>Hospitality</p>
                                    <h3><Link to="/project">Cotton House</Link></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 single-item hospitality">
                            <div class="bauen-project-wrap">
                                <Link to="/project"><img src="assets/img/projects/2.jpg" alt="" /></Link>
                                <div class="bauen-project-content">
                                    <p>Hospitality</p>
                                    <h3><Link to="/project">Armada Center</Link></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 single-item residential">
                            <div class="bauen-project-wrap">
                                <Link to="/project"><img src="assets/img/projects/5.jpg" alt="" /></Link>
                                <div class="bauen-project-content">
                                    <p>Residential</p>
                                    <h3><Link to="/project">Geometric ADDO</Link></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 single-item residential">
                            <div class="bauen-project-wrap">
                                <Link to="/project"><img src="assets/img/projects/3.jpg" alt="" /></Link>
                                <div class="bauen-project-content">
                                    <p>Residential</p>
                                    <h3><Link to="/project">Stonya Villa</Link></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 single-item residential">
                            <div class="bauen-project-wrap">
                                <Link to="/project"><img src="assets/img/projects/4.jpg" alt="" /></Link>
                                <div class="bauen-project-content">
                                    <p>Residential</p>
                                    <h3><Link to="/project">Prime Hotel</Link></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 single-item interior">
                            <div class="bauen-project-wrap">
                                <Link to="/project"><img src="assets/img/projects/6.jpg" alt="" /></Link>
                                <div class="bauen-project-content">
                                    <p>Interior</p>
                                    <h3><Link to="/project">Twins Office</Link></h3>
                                </div>
                            </div>
                        </div> */}
                        {/* <input name="submit" type="submit" value="Send Message" /> */}
                    </div>
                </div>
                        {/* <button onClick={loadMore}>Load More</button> */}
            </section>

        </div>
    )
}
